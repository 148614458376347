import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import './blog.scss'

const Blog = () => (
  <Layout>
    <SEO title="Blog" />
    <div className='blog'>
      <h1>Blog</h1>
      <p>This is something that I wrote.</p>
      <ul>
        <li>
          <a href='https://blog.zatsuzen.com' target='_blank' rel='noopener' className='blog-zatsuzen'>
            <div className='img'>zatsuzen</div>
            <div className='title'>ゆったりWeb手帳(ja)</div>
          </a>
        </li>
      </ul>
    </div>
    <div className='back-to-home'><Link to='/'>Home</Link></div>
  </Layout>
)

export default Blog
